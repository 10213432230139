import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import msalInstance from "./msalConfig";
import { setManager, setUser, setAllUsers, setUserIsHRAdmin, setUserIsItAdmin, setAPIToken, setUserValid, setLoginFailure } from '../redux/actions/userActions';
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Typography, Button } from '@mui/material';
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

const GetUserDetails = ({ setLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoadingState] = useState(true);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const user = useSelector((state) => state.user.user);
  const loginFailures  = useSelector((state) => state.user.loginFailure);
  

  useEffect(() => {
    const initializeMsal = async () => {
      await msalInstance.initialize();
      const loginRequest = {
        scopes: ["https://graph.microsoft.com/.default"],
      };

      try {
        const loginResponse = await msalInstance.ssoSilent(loginRequest);
        handleLoginResponse(loginResponse);



        // Acquire API token with different scopes
        await acquireAPIToken();

        // Handle token refresh for loginResponse
        const expiresOn = new Date(loginResponse.expiresOn);
        const timeUntilExpiration = expiresOn - new Date();
        // Set a timeout to refresh the token 1 minute before it expires
        setTimeout(initializeMsal, timeUntilExpiration - 60000); // 60000 ms = 1 minute
        console.log("Login token successfully retrieved, expiration in: ", timeUntilExpiration, "at timestamp: ", expiresOn);
        dispatch(setLoginFailure(0))
      } catch (silentError) {
        console.log(silentError, silentError.errorCode);
        setLoadingState(false);
        setLoading(false);
      }
    };

    

    const handleLoginResponse = async (loginResponse) => {
      const accessToken = loginResponse.accessToken;
      const hrAdminGroupName = process.env.REACT_APP_TUITION_REMIBURSEMENT_ADMIN_GROUP_NAME;
      const itAdminGroupName = process.env.REACT_APP_TUITION_REMIBURSEMENT_ITADMIN_GROUP_NAME;
      // Fetch user information
      const userResponse = await fetch("https://graph.microsoft.com/v1.0/me?$select=id,displayName,jobTitle,mail,department,title,mobilePhone,onPremisesExtensionAttributes", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userData = await userResponse.json();
      dispatch(setUser(userData));

      // Fetch manager information
      const managerResponse = await fetch("https://graph.microsoft.com/v1.0/me/manager?$select=id,displayName,jobTitle,mail,department,title,mobilePhone,onPremisesExtensionAttributes", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const managerData = await managerResponse.json();
      dispatch(setManager(managerData));

      let userListData = await getAllADUserData(accessToken);
      dispatch(setAllUsers(userListData));

      // Check if user is a member of the HR Admin group
      const isMemberHrAdmin = await isUserMemberOfGroupByName(accessToken, hrAdminGroupName);
      dispatch(setUserIsHRAdmin(isMemberHrAdmin));
      console.log("Is user a member of the HR Admin group?", isMemberHrAdmin);

      // Check if user is a member of the IT Admin group
      const isMemberItAdmin = await isUserMemberOfGroupByName(accessToken, itAdminGroupName);
      dispatch(setUserIsItAdmin(isMemberItAdmin));
      console.log("Is user a member of the IT Admin group?", isMemberItAdmin);

      setLoadingState(false);
      setLoading(false);
    };

    const acquireAPIToken = async () => {
      const apiTokenRequest = {
        scopes: ["api://e8fbcc85-627d-4be0-931b-d5b8044300f0/Endpoints.Request"],
      };

      try {
        const apiTokenSSoResponse = await msalInstance.ssoSilent(apiTokenRequest);
        const apiAccessToken = apiTokenSSoResponse.accessToken;
        const expiresOn = new Date(apiTokenSSoResponse.expiresOn); 
        
        dispatch(setAPIToken(apiAccessToken));
        // Calculate the time until the token expires
        const timeUntilExpiration = expiresOn - new Date();
        console.log("API access token successfully retrieved, expiration in: ", timeUntilExpiration, "at timestamp: ", expiresOn);

        // Set a timeout to refresh the token 1 minute before it expires
        setTimeout(acquireAPIToken, timeUntilExpiration - 60000); // 60000 ms = 1 minute

      } catch (error) {
        console.error("Error acquiring API access token:", error);
      }
    };

    async function fetchAllUsers(accessToken) {
      let users = [];
      let url = "https://graph.microsoft.com/v1.0/users?$count=true&$top=999&$filter=onPremisesExtensionAttributes/extensionAttribute2 eq 'FT' or onPremisesExtensionAttributes/extensionAttribute2 eq 'LOA'&$select=id,displayName,jobTitle,mail,department,title,mobilePhone,onPremisesExtensionAttributes&$orderby=displayName";
      
      while (url) {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ConsistencyLevel: 'eventual'
          }
        });
        const data = await response.json();
        users = users.concat(data.value);
        url = data['@odata.nextLink']; // Get the next page URL
      }
      
      return users;
    }

    async function getAllADUserData(accessToken) {
      const users = await fetchAllUsers(accessToken);
      const employeeIds = users.map(user => ({
        displayName: user.displayName,
        jobTitle: user.jobTitle,
        department: user.department,
        mail: user.mail,
        mobilePhone: user.mobilePhone,
        id: user.id,
        employeeId: user.onPremisesExtensionAttributes.extensionAttribute3
      }));
      return employeeIds;
    }

    async function isUserMemberOfGroupByName(accessToken, groupName) {
      try {
        let url = "https://graph.microsoft.com/v1.0/me/memberOf";
        let isMember = false;
    
        while (url) {
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const data = await response.json();
          const groups = data.value;
    
          if (groups.some(group => group.displayName === groupName)) {
            isMember = true;
            break;
          }
    
          url = data['@odata.nextLink']; // Get the next page URL
        }
    
        return isMember;
      } catch (error) {
        console.error("Error checking group membership:", error);
        return false;
      }
    }

    initializeMsal();
  }, [dispatch, setLoading]);


  const authenticateUser = async () => {
    
    const loginRequest = {
      scopes: ["https://graph.microsoft.com/.default"],
    };
    try {
      await msalInstance.handleRedirectPromise();
      const interactiveLoginResponse = await msalInstance.loginPopup(loginRequest);
      handleLoginResponse(interactiveLoginResponse);

      // Acquire API token with different scopes
      await acquireAPIToken();
      
      dispatch(setLoginFailure(0));
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.log("Interaction required:", error);
        // Prompt user to log in again
        const interactiveLoginResponse = await msalInstance.loginPopup(loginRequest);
        handleLoginResponse(interactiveLoginResponse);

        // Acquire API token with different scopes
        await acquireAPIToken();

      } else {
        console.log(error);
        dispatch(setLoginFailure(loginFailures + 1));
      }
    };
  };
  
  const acquireAPIToken = async () => {
    const apiTokenRequest = {
      scopes: ["api://e8fbcc85-627d-4be0-931b-d5b8044300f0/Endpoints.Request"],
    };
  
    try {
      const apiTokenSSoResponse = await msalInstance.ssoSilent(apiTokenRequest);
      const apiAccessToken = apiTokenSSoResponse.accessToken;
      const expiresOn = new Date(apiTokenSSoResponse.expiresOn);
  
      dispatch(setAPIToken(apiAccessToken));
      // Calculate the time until the token expires
      const timeUntilExpiration = expiresOn - new Date();
      console.log("API access token successfully retrieved, expiration in: ", timeUntilExpiration, "at timestamp: ", expiresOn);
  
      // Set a timeout to refresh the token 1 minute before it expires
      setTimeout(acquireAPIToken, timeUntilExpiration - 60000); // 60000 ms = 1 minute
  
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.log("Interaction required, prompting user to log in.");
        try {
          const secondaryLoginResponse = await msalInstance.loginPopup(apiTokenRequest);
          const secondaryAccessToken = secondaryLoginResponse.accessToken;
          dispatch(setAPIToken(secondaryAccessToken));
          const secondaryExpiresOn = new Date(secondaryLoginResponse.expiresOn);
          //handleLoginResponse(secondaryLoginResponse);
        } catch (loginError) {
          console.error("Error during interactive login:", loginError);
        }
      } else {
        console.error("Error acquiring API access token:", error);
      }
    }
  };

  const loginRedirect = async () => {
   
    try{
     authenticateUser();
    } catch(error){
      console.log(error);
      dispatch(setLoginFailure(loginFailures + 1))
    }
   
  }

  const handleLoginResponse = async (loginResponse) => {
    const accessToken = loginResponse.accessToken;
    const hrAdminGroupName = process.env.REACT_APP_TUITION_REMIBURSEMENT_ADMIN_GROUP_NAME;
    const itAdminGroupName = process.env.REACT_APP_TUITION_REMIBURSEMENT_ITADMIN_GROUP_NAME;
    // Fetch user information
    const userResponse = await fetch("https://graph.microsoft.com/v1.0/me?$select=id,displayName,jobTitle,mail,department,title,mobilePhone,onPremisesExtensionAttributes", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const userData = await userResponse.json();
    dispatch(setUser(userData));

    // Fetch manager information
    const managerResponse = await fetch("https://graph.microsoft.com/v1.0/me/manager?$select=id,displayName,jobTitle,mail,department,title,mobilePhone,onPremisesExtensionAttributes", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const managerData = await managerResponse.json();
    dispatch(setManager(managerData));

    let userListData = await getAllADUserData(accessToken);
    dispatch(setAllUsers(userListData));

    // Check if user is a member of the HR Admin group
    const isMemberHrAdmin = await isUserMemberOfGroupByName(accessToken, hrAdminGroupName);
    dispatch(setUserIsHRAdmin(isMemberHrAdmin));
    console.log("Is user a member of the HR Admin group?", isMemberHrAdmin);

    // Check if user is a member of the IT Admin group
    const isMemberItAdmin = await isUserMemberOfGroupByName(accessToken, itAdminGroupName);
    dispatch(setUserIsItAdmin(isMemberItAdmin));
    console.log("Is user a member of the IT Admin group?", isMemberItAdmin);

    setLoadingState(false);
    setLoading(false);
  };

  async function getAllADUserData(accessToken) {
    const users = await fetchAllUsers(accessToken);
    const employeeIds = users.map(user => ({
      displayName: user.displayName,
      jobTitle: user.jobTitle,
      department: user.department,
      mail: user.mail,
      mobilePhone: user.mobilePhone,
      id: user.id,
      employeeId: user.onPremisesExtensionAttributes.extensionAttribute3
    }));
    return employeeIds;
  }

  async function isUserMemberOfGroupByName(accessToken, groupName) {
    try {
      let url = "https://graph.microsoft.com/v1.0/me/memberOf";
      let isMember = false;
  
      while (url) {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        const groups = data.value;
  
        if (groups.some(group => group.displayName === groupName)) {
          isMember = true;
          break;
        }
  
        url = data['@odata.nextLink']; // Get the next page URL
      }
  
      return isMember;
    } catch (error) {
      console.error("Error checking group membership:", error);
      return false;
    }
  }

  async function fetchAllUsers(accessToken) {
    let users = [];
    let url = "https://graph.microsoft.com/v1.0/users?$count=true&$top=999&$filter=onPremisesExtensionAttributes/extensionAttribute2 eq 'FT' or onPremisesExtensionAttributes/extensionAttribute2 eq 'LOA'&$select=id,displayName,jobTitle,mail,department,title,mobilePhone,onPremisesExtensionAttributes&$orderby=displayName";
    
    while (url) {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ConsistencyLevel: 'eventual'
        }
      });
      const data = await response.json();
      users = users.concat(data.value);
      url = data['@odata.nextLink']; // Get the next page URL
    }
    
    return users;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  } else if (!user && !loading){
    return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Button  color="inherit" onClick={() => loginRedirect()} >Click here to Log In with SSO</Button>
      { loginFailures > 0 &&
      <div>
          <Typography variant="h4" gutterBottom>
          Login has failed or your authentication token has expired. Please refresh your page, and make sure your login is attempted from the Pharmavite network. 
        </Typography>
        <Typography variant='h6'style={{ textAlign: 'center', marginTop: '10px' }}> 
          If you are logged in to the Pharmavite network, please attempt a page refresh. If login continues to fail, please contact an IT Admin.
        </Typography>
      </div>
      }
      <br />
      
    </Container>
    )
  } 

  return null;
};

export default GetUserDetails;
