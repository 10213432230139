import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, List, ListItem, ListItemText, Button, Container, Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { setNewRequestSomeoneElseSelection, setNewRequestSomeoneElseSelectionManager } from './redux/actions/userActions'; // Adjust the import path as needed
import msalInstance from "./UserDetails/msalConfig.js";
import { Helmet } from 'react-helmet';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to right, #d96704, #e88c2a)',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundImage: 'linear-gradient(to right, #e88c2a, #d96704)',
  },
}));

const UserSearch = () => {
  const userListFromStore = useSelector((state) => state.user.allUsers);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserManager, setSelectedUserManager] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();



  const handleSearch = (event) => {
    const value = event.target.value;
    const valueLookup = event.target.value.toLowerCase();
    if(selectedUser){
      setSelectedUser(null);
    }
    setSearchTerm(value);
    if (value.length >= 2) {
      const filtered = userListFromStore.filter(user =>
        (user.displayName && user.displayName.toLowerCase().includes(valueLookup)) ||
        (user.jobTitle && user.jobTitle.toLowerCase().includes(valueLookup)) ||
        (user.department && user.department.toLowerCase().includes(valueLookup)) ||
        (user.mail && user.mail.toLowerCase().includes(valueLookup))
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setSearchTerm(user.displayName)
    getManagerForSelectedUser(user);
    dispatch(setNewRequestSomeoneElseSelection(user));
  };

  const handleCreateRequest = () => {
    navigate('/new-request-someone-else');
  };

  const getManagerForSelectedUser = (user) => {
    setLoading(true);
    const initializeMsal = async () => {
      await msalInstance.initialize();
      const loginRequest = {
        scopes: ["https://graph.microsoft.com/.default"],
      };

      try {
        const loginResponse = await msalInstance.ssoSilent(loginRequest);
        handleLoginResponse(loginResponse, user);
        setLoading(false);
      } catch (silentError) {
        if (silentError.errorCode === "interaction_required" || silentError.errorCode === "login_required") {
          console.log(silentError, silentError.errorCode);
          msalInstance.handleRedirectPromise().then(() => {
            const interactiveLoginResponse = msalInstance.loginRedirect(loginRequest);
            handleLoginResponse(interactiveLoginResponse);
            setLoading(false);
          }).catch((e) => console.log(e));
        } else {
          console.error("Silent login failed:", silentError);
          setLoading(false);
        }
      }
    };

    initializeMsal();
  };

  const handleLoginResponse = async (loginResponse, user) => {
    // Fetch user information
    const selectedUserManagerResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${user.id}/manager?$select=id,displayName,jobTitle,mail,department,title,mobilePhone,onPremisesExtensionAttributes`, {
      headers: {
        Authorization: `Bearer ${loginResponse.accessToken}`,
      },
    });
    const selectedUserManagerData = await selectedUserManagerResponse.json();
    setSelectedUserManager(selectedUserManager);
    dispatch(setNewRequestSomeoneElseSelectionManager(selectedUserManagerData))
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>User Search - Request for Someone Else</title>
        <meta name="description" content="Tuition Reimbursement - User Search For Request For Someone Else" />
      </Helmet>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="90vh">
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Search Users
          </Typography>
          <TextField
            label="Search Users"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          { selectedUser && !loading && (
              <Box display="flex" justifyContent="flex-start" >
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleCreateRequest}

              >
                Create a New Request
              </StyledButton>
              </Box>
          )}
         
        </StyledPaper>       
        <StyledPaper style={{ height: '40vh', overflow: 'auto' }}>
          <List>
            {filteredUsers.map((user) => (
              <ListItem
                key={user.id}
                button
                selected={selectedUser && selectedUser.id === user.id}
                onClick={() => handleSelectUser(user)}
              >
                <ListItemText
                  primary={user.displayName}
                  secondary={`${user.jobTitle} | ${user.department} | ${user.mail}`}
                />
              </ListItem>
            ))}
          </List>
          
        </StyledPaper>
      </Box>
    </Container>
  );
};

export default UserSearch;
