import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, Typography, CssBaseline, Box, Button, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to right, #d96704, #e88c2a)',
}));

const NavigationLayout = ({ children, loading }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);
  const user = useSelector((state) => state.user.user);
  const manager = useSelector((state) => state.user.manager);
  const hrAdmin = useSelector((state) => state.user.userIsHrAdmin)
  const itAdmin = useSelector((state) => state.user.userIsItAdmin)
  const userValid = useSelector((state) => state.user.userValid)
  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const handleAdminMenuClick = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAnchorElAdmin(null);
  };

  const handleAdminMenuItemClick = (path) => {
    navigate(path);
    handleAdminMenuClose();
  };

  const drawerContent = 
  user != null ?
  itAdmin || hrAdmin ?
  (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={() => navigate('/new-request')}>
          <ListItemText primary="New Request For Me" />
        </ListItem>
        <ListItem button onClick={() => navigate('/user-search')}>
          <ListItemText primary="New Request For Someone Else" />
        </ListItem>
        <ListItem button onClick={() => navigate('/previous-requests')}>
          <ListItemText primary="My Previous Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate('/awaiting-my-approval')}>
          <ListItemText primary="Awaiting My Approval" />
        </ListItem>
        <ListItem button onClick={() => navigate('/all-requests')}>
          <ListItemText primary="All Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate('/all-manager-pending-requests')}>
          <ListItemText primary="Manager Pending Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate('/all-manager-rejected-requests')}>
          <ListItemText primary="Manager Rejected Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate('/all-hr-pending-requests')}>
          <ListItemText primary="HR Pending Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate('/all-hr-rejected-requests')}>
          <ListItemText primary="HR Rejected Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate('/all-approved-requests')}>
          <ListItemText primary="Approved Requests" />
        </ListItem>
      </List>
    </Box>
  ) : (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={() => navigate('/new-request')}>
          <ListItemText primary="New Request For Me" />
        </ListItem>
        <ListItem button onClick={() => navigate('/user-search')}>
          <ListItemText primary="New Request For Someone Else" />
        </ListItem>
        <ListItem button onClick={() => navigate('/previous-requests')}>
          <ListItemText primary="My Previous Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate('/awaiting-my-approval')}>
          <ListItemText primary="Awaiting My Approval" />
        </ListItem>
        
      </List>
    </Box>
  ) : <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    ></Box>;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <StyledAppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img src="/Pharmavite_white_headerlogo.a38156a4.png" alt="Logo" style={{ marginRight: '16px', height: '40px' }} />
          <Typography 
            variant="h6" 
            noWrap 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer'}} 
            title={"Click to go to Home page"}
            onClick={() => navigate('/')}>
            Tuition Reimbursement
          </Typography>
          { !loading && user !== null &&
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            { (hrAdmin || itAdmin) &&
              <Button style={{ marginRight: '10px', marginLeft: '5px' }} color="inherit" onClick={handleAdminMenuClick} >Admin </Button>
            }
            <Menu
              anchorEl={anchorElAdmin}
              open={Boolean(anchorElAdmin)}
              onClose={handleAdminMenuClose}
            >
              <MenuItem onClick={() => handleAdminMenuItemClick('/all-requests')}>All Requests</MenuItem>
              <MenuItem onClick={() => handleAdminMenuItemClick('/all-manager-pending-requests')}>Manager Pending Requests</MenuItem>
              <MenuItem onClick={() => handleAdminMenuItemClick('/all-manager-rejected-requests')}>Manager Rejected Requests</MenuItem>
              <MenuItem onClick={() => handleAdminMenuItemClick('/all-hr-pending-requests')}>HR Pending Requests</MenuItem>
              <MenuItem onClick={() => handleAdminMenuItemClick('/all-hr-rejected-requests')}>HR Rejected Requests</MenuItem>
              <MenuItem onClick={() => handleAdminMenuItemClick('/all-approved-requests')}>Approved Requests</MenuItem>
            </Menu>
            { (hrAdmin || itAdmin)  &&
              <Divider orientation="vertical" flexItem sx={{ mx: 1, bgcolor: 'white' }} />
            }
            <Button style={{ marginRight: '5px', marginLeft: '5px' }} color="inherit" onClick={handleMenuClick} >New Request</Button> 
            <Divider orientation="vertical" flexItem sx={{ mx: 1, bgcolor: 'white' }} />
            <Button style={{ marginRight: '5px', marginLeft: '5px' }} color="inherit" onClick={() => navigate('/previous-requests')} >My Previous Requests</Button> 
            <Divider orientation="vertical" flexItem sx={{ mx: 1, bgcolor: 'white' }} />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick('/new-request')}>New Request For Me</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/user-search')}>New Request For Someone Else</MenuItem>
            </Menu>
            <Button color="inherit" style={{marginRight: '77px'}} onClick={() => navigate('/awaiting-my-approval')} >Awaiting My Approval</Button>
            
          </Box>
          }
          { !loading && user !== null &&
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="h9" 
              noWrap 
              component="div" 
              sx={{ cursor: 'pointer'}} 
              title={manager ? `Manager:  ${manager.displayName}\nTitle:  ${user.jobTitle}\nDepartment:  ${user.department}\nEmail:  ${user.mail}` : ""}
            >
              {loading ? "" : user ? user.displayName : ""}
            </Typography>
            <AccountCircleIcon style={{ fontSize:20, color: 'white', marginLeft: '7px' }} />
          </Box>
          }
        </Toolbar>
      </StyledAppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerContent}
      </Drawer>
      <Main>
        <Toolbar />
        {children}
      </Main>
    </Box>
  );
};

export default NavigationLayout;
