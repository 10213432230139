// src/redux/reducers/userReducer.js

import { setAllUsers } from "../actions/userActions";

const initialState = {
    user: null,
    manager: null,
    allUsers: [],
    newRequestSomeoneElseSelection: null,
    newRequestSomeoneElseSelectionManager: null,
    userIsHrAdmin: false,
    userIsItAdmin: false,
    apiToken: null,
    userValid: true,
    loginFailure: 0
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER':
        return {
          ...state,
          user: action.payload,
        };
      case 'SET_MANAGER':
        return {
          ...state,
          manager: action.payload,
        };
      case 'SET_ALLUSERS':
        return {
          ...state,
          allUsers: action.payload,
        };
      case 'SET_NEW_REQUEST_SOMEONE_ELSE_SELECTION':
        return {
          ...state,
          newRequestSomeoneElseSelection: action.payload,
        };

      case 'SET_NEW_REQUEST_SOMEONE_ELSE_SELECTION_MANAGER':
        return {
          ...state,
          newRequestSomeoneElseSelectionManager: action.payload,
        };
      case 'SET_USER_IS_HR_ADMIN':
        return {
          ...state,
          userIsHrAdmin: action.payload,
        };
      case 'SET_USER_IS_IT_ADMIN':
        return {
          ...state,
          userIsItAdmin: action.payload,
        };
      case 'SET_API_TOKEN':
        return {
          ...state,
          apiToken: action.payload,
        };
      case 'SET_USER_VALID':
        return {
          ...state,
          userValid: action.payload,
        };
      case 'SET_LOGIN_FAILURE':
      return {
        ...state,
        loginFailure: action.payload,
      };
      default:
        return state;
    }
  };
  
  export default userReducer;
  