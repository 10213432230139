import React from 'react';
import { Container, Typography, Button } from '@mui/material';

const LoginError = ({ message, onRetry }) => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Login has failed, Please make sure your login is attempted from the Pharmavite network. 
      </Typography>
      <Typography variant='h6'style={{ textAlign: 'center', marginTop: '10px' }}> 
        If you are logged in to the Pharmavite network, please attempt a page refresh. If login continues to fail, please contact an IT Admin.
      </Typography>
    </Container>
  );
};

export default LoginError;