// src/App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationLayout from "./NavigationLayout";
import Home from "./Home";
import RequestForm from "./RequestForm";
import RequestDetails from "./RequestDetails"; // Import the new component
import GetUserDetails from "./UserDetails/GetUserDetails";
import UserSearch from "./UserSearch";
import RequestFormSomeoneElse from "./RequestFormSomeoneElse";
import { Box, CircularProgress } from "@mui/material";
import MyPreviousRequests from './MyPreviousRequests';
import AllRequests from "./AllRequests";
import ManagerPendingApprovalRequests from "./ManagerPendingApprovalRequests";
import ManagerRejectedRequests from "./ManagerRejectedRequests";
import HrPendingApprovalRequests from "./HrPendingApprovalRequests";
import HrRejectedRequests from "./HrRejectedRequests";
import ApprovedRequests from "./ApprovedRequests";
import AwaitingMyApproval from "./AwaitingMyApproval";
import LoginError from "./LoginError";

const App = () => {

  const [loading, setLoading] = useState(true);

  return (
    <Router>
      <NavigationLayout loading={loading}>
        <GetUserDetails setLoading={setLoading} />
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/new-request" element={<RequestForm  />} />
            <Route path="/new-request-someone-else" element={<RequestFormSomeoneElse  />} />
            <Route path="/requests/:id" element={<RequestDetails />} /> 
            <Route path="/previous-requests" element={<MyPreviousRequests />} />
            <Route path="/user-search" element={<UserSearch />} />
            <Route path='/all-requests' element={<AllRequests />} />
            <Route path='/all-manager-pending-requests' element={<ManagerPendingApprovalRequests />} />
            <Route path='/all-manager-rejected-requests' element={<ManagerRejectedRequests />} />
            <Route path='/all-hr-pending-requests' element={<HrPendingApprovalRequests />} />
            <Route path='/all-hr-rejected-requests' element={<HrRejectedRequests />} />
            <Route path='/all-approved-requests' element={<ApprovedRequests />} />
            <Route path='/awaiting-my-approval' element={<AwaitingMyApproval />} />
            <Route path='/login-error' element={<LoginError />} />
          </Routes>
        )}
      </NavigationLayout>
    </Router>
  );
};

export default App;
