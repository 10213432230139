// src/redux/actions/userActions.js

import { all } from "axios";

// Action to set user details
export const setUser = (user) => ({
    type: 'SET_USER',
    payload: user,
  });
  
  // Action to set manager details
  export const setManager = (manager) => ({
    type: 'SET_MANAGER',
    payload: manager,
  });

  export const setAllUsers = (allUsers) => ({
    type: 'SET_ALLUSERS',
    payload: allUsers,
  });

  export const setNewRequestSomeoneElseSelection = (selection) => ({
    type: 'SET_NEW_REQUEST_SOMEONE_ELSE_SELECTION',
    payload: selection,
  });
  
  export const setNewRequestSomeoneElseSelectionManager = (selectionManager) => ({
    type: 'SET_NEW_REQUEST_SOMEONE_ELSE_SELECTION_MANAGER',
    payload: selectionManager,
  });

  export const setUserIsHRAdmin = (isHrAdmin) => ({
    type: 'SET_USER_IS_HR_ADMIN',
    payload: isHrAdmin,
  });

  export const setUserIsItAdmin = (isItAdmin) => ({
    type: 'SET_USER_IS_IT_ADMIN',
    payload: isItAdmin,
  });

  export const setAPIToken = (apiToken) => ({
    type: 'SET_API_TOKEN',
    payload: apiToken,
  });

  export const setUserValid = (userValid) => ({
    type: 'SET_USER_VALID',
    payload: userValid,
  });

  export const setLoginFailure = (loginFailure) => ({
    type: 'SET_LOGIN_FAILURE',
    payload: loginFailure,
  });
