// src/RequestDetails.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Grid, TextField, FormControlLabel, Switch, Paper, Badge, Button, Snackbar, Alert, CircularProgress  } from '@mui/material';
import { styled } from "@mui/material/styles";
import numeral from 'numeral';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: theme.shadows[3],
  }));
  
const RequestDetails = () => {
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { id } = useParams();
  const [total, setTotal] = useState('0.00');
  const [requestDetails, setRequestDetails] = useState(null);
  const isMemberHrAdmin = useSelector((state) => state.user.userIsHrAdmin);
  const isMemberItAdmin = useSelector((state) => state.user.userIsItAdmin);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user.user);
  const apiToken = useSelector((state) => state.user.apiToken);
  const apiRootUrl = process.env.REACT_APP_TUITION_REMIBURSEMENT_API_ROOTURL;

  useEffect(() => {
    if (!userDetails) {
      navigate('/'); // Redirect to login if user is not logged in
      return;
    }
    // Fetch the request data by ID
    fetch(`${apiRootUrl}/api/requests/${id}`, 
      {
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }
    })
      .then(response => response.json())
      .then(data => setRequestDetails(data))
      .catch(error => console.error('Error fetching request data:', error));
  }, [id, apiToken, userDetails]);


  useEffect(() => {
    
    if (requestDetails) {
      const fees = requestDetails.fees;
      const sum = Object.entries(fees)
        .filter(([key]) => key !== 'id') // Exclude the ID property
        .reduce((acc, [key, fee]) => acc + parseFloat(fee || 0), 0);
      setTotal(numeral(sum).format('0,0.00'));
    }
  }, [requestDetails, apiToken]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const StyledBadge = styled(Badge)(({ theme, status }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: status === 'Approved' ? 'green' : status === 'Rejected' ? 'red' : 'orange',
        color: 'white',
    },
}));

const styles = {
    container: {
        display: 'flex',
        gap: '20px',
        margin: '20px 0',
        marginLeft: '30px'
    },
    span: {
        marginRight: '10px',
    }
};

const handleSubmitManagerApproval = async (e) => {
    e.preventDefault();
    
    const approvePayload = {
      managerStatus: "Approved",
      managerEntraId: userDetails.id,
      managerEmployeeId: Number(userDetails.onPremisesExtensionAttributes.extensionAttribute3),
      managerEmail: userDetails.mail,
      managerName: userDetails.displayName,
    };

    try {
      // Update the approval status
      await axios.put(`${apiRootUrl}/api/Requests/${id}/manager-approve`, approvePayload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        }
      });

      // Fetch the updated request details
      const response = await fetch(`${apiRootUrl}/api/requests/${id}`, {
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }
      });
      const data = await response.json();
      setRequestDetails(data);

      // Show success Snackbar
      setSnackbarMessage('Approval was successful!');
      setSnackbarSeverity('success');
      setOpen(true);
    } catch (error) {
      console.error('Error updating approval status or fetching request data:', error);

      // Show error Snackbar
      setSnackbarMessage('Failed to update approval status.');
      setSnackbarSeverity('error');
      setOpen(true);
    }
  };

  const handleSubmitManagerReject = async (e) => {
    e.preventDefault();

    const rejectPayload = {
      managerStatus: "Rejected",
      managerEntraId: userDetails.id,
      managerEmployeeId: Number(userDetails.onPremisesExtensionAttributes.extensionAttribute3),
      managerEmail: userDetails.mail,
      managerName: userDetails.displayName,
    };

    try {
      // Update the approval status
      await axios.put(`${apiRootUrl}/api/Requests/${id}/manager-reject`, rejectPayload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        }
      });

      // Fetch the updated request details
      const response = await fetch(`${apiRootUrl}/api/requests/${id}`, {
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }
      });
      const data = await response.json();
      setRequestDetails(data);

      // Show success Snackbar
      setSnackbarMessage('Rejection was successful!');
      setSnackbarSeverity('success');
      setOpen(true);
    } catch (error) {
      console.error('Error updating approval status or fetching request data:', error);

      // Show error Snackbar
      setSnackbarMessage('Failed to update rejection status.');
      setSnackbarSeverity('error');
      setOpen(true);
    }
  };

  const handleSubmitHRApproval = async (e) => {
    e.preventDefault();

    const approvePayload = {
      hrStatus: "Approved",
      hrEntraId: userDetails.id,
      hrEmployeeId: Number(userDetails.onPremisesExtensionAttributes.extensionAttribute3),
      hrEmail: userDetails.mail,
      hrName: userDetails.displayName,
    };

    try {
      // Update the approval status
      await axios.put(`${apiRootUrl}/api/Requests/${id}/hr-approve`, approvePayload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        }
      });

      // Fetch the updated request details
      const response = await fetch(`${apiRootUrl}/api/requests/${id}`, {
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }
      });
      const data = await response.json();
      setRequestDetails(data);

      // Show success Snackbar
      setSnackbarMessage('Approval was successful!');
      setSnackbarSeverity('success');
      setOpen(true);
    } catch (error) {
      console.error('Error updating approval status or fetching request data:', error);

      // Show error Snackbar
      setSnackbarMessage('Failed to update approval status.');
      setSnackbarSeverity('error');
      setOpen(true);
    }
  };

  const handleSubmitHRReject = async (e) => {
    e.preventDefault();

    const approvePayload = {
      hrStatus: "Rejected",
      hrEntraId: userDetails.id,
      hrEmployeeId: Number(userDetails.onPremisesExtensionAttributes.extensionAttribute3),
      hrEmail: userDetails.mail,
      hrName: userDetails.displayName,
    };

    try {
      // Update the approval status
      await axios.put(`${apiRootUrl}/api/Requests/${id}/hr-reject`, approvePayload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        }
      });

      // Fetch the updated request details
      const response = await fetch(`${apiRootUrl}/api/requests/${id}`, {
        headers: {
          'Authorization': `Bearer ${apiToken}`
        }
      });
      const data = await response.json();
      setRequestDetails(data);

      // Show success Snackbar
      setSnackbarMessage('Rejection was successful!');
      setSnackbarSeverity('success');
      setOpen(true);
    } catch (error) {
      console.error('Error updating approval status or fetching request data:', error);

      // Show error Snackbar
      setSnackbarMessage('Failed to update approval status.');
      setSnackbarSeverity('error');
      setOpen(true);
    }
  };


  if (!requestDetails) {
    return(
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>)
  }

  return (
    <Container maxWidth="xl">
       <Helmet>
        <title>{`Request Detail For ${requestDetails.requestor.name}`}</title>
        <meta name="description" content={`Tuition Reimbursement - Request Detail For ${requestDetails.requestor.name}`} />
      </Helmet>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          View Tuition Reimbursement Request
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
          <StyledPaper sx={{ mb: 4 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Requestor Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                value={requestDetails.requestor.name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                margin="normal"
                value={requestDetails.requestor.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Department"
                variant="outlined"
                margin="normal"
                value={requestDetails.requestor.department}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Job Title"
                variant="outlined"
                margin="normal"
                value={requestDetails.requestor.jobTitle}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Manager Name"
                variant="outlined"
                margin="normal"
                value={requestDetails.requestor.managerName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Manager Email"
                variant="outlined"
                margin="normal"
                value={requestDetails.requestor.managerEmail}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </StyledPaper>
            <StyledPaper>
                <Typography variant="h6" component="h2" gutterBottom>
                    School and Class Details
                </Typography>
              <TextField
                fullWidth
                label="Name of School"
                variant="outlined"
                margin="normal"
                value={requestDetails.schoolName}
                inputprops={{readOnly: true}}
              />
              <Box sx={{ mb: 4 }} />
              {requestDetails.courses.map((course, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Course Name"
                        variant="outlined"
                        margin="normal"
                        value={course.courseName}
                        inputprops={{readOnly: true}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Start Date"
                       
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        margin="normal"
                        value={new Date(course.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        inputprops={{readOnly: true}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="End Date"
                      
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        margin="normal"
                        value={new Date(course.endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        inputprops={{readOnly: true}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControlLabel
                        control={<Switch checked={course.classroom} inputprops={{readOnly: true}} />}
                        label="Classroom"
                        sx={{ mt: 2 }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Box sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DollarInput label="Registration Fee" value={numeral(requestDetails.fees.registration).format('0,0.00')}  onChange={() => {}} disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DollarInput label="Tuition Fee" value={numeral(requestDetails.fees.tuition).format('0,0.00')}  onChange={() => {}} disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DollarInput label="Textbooks Fee" value={numeral(requestDetails.fees.textbooks).format('0,0.00')} onChange={() => {}} disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DollarInput label="Parking Fee" value={numeral(requestDetails.fees.parking).format('0,0.00')}  onChange={() => {}} disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DollarInput label="Laboratory Fee" value={numeral(requestDetails.fees.laboratory).format('0,0.00')}  onChange={() => {}} disabled />
                  </Grid>
                </Grid>
              </Box>
              
               
            </StyledPaper>
            
          </Grid>
          <Grid item xs={12} md={4}>
          {requestDetails.approvalStatus.managerStatus === "Pending" && userDetails.id === requestDetails.requestor.managerEntraId && (
          <StyledPaper>
            <Box sx={{ mb: 2}}>
                  <Typography sx={{ mb: 2}} variant="h6" component="h2" gutterBottom >
                        Manager Approval Actions
                  </Typography>
                  <Button variant="contained" color="success" type="submit" style={{marginTop: '10px'}} onClick={handleSubmitManagerApproval}>
                    Approve Request
                  </Button>
                  <Button variant="contained" color="error" type="submit" style={{marginLeft: '10px', marginTop: '10px'}}onClick={handleSubmitManagerReject} >
                    Reject Request
                  </Button>
                </Box>
               
            </StyledPaper> )}
            {requestDetails.approvalStatus.hrStatus === "Pending" && requestDetails.approvalStatus.managerStatus === "Approved" && isMemberHrAdmin === true && (
          <StyledPaper>
            <Box sx={{ mb: 2}}>
                  <Typography sx={{ mb: 2}} variant="h6" component="h2" gutterBottom >
                        HR Approval Actions
                  </Typography>
                  <Button variant="contained" color="success" type="submit" style={{marginTop: '10px'}} onClick={handleSubmitHRApproval}>
                    Approve Request
                  </Button>
                  <Button variant="contained" color="error" type="submit" style={{marginLeft: '10px', marginTop: '10px'}} onClick={handleSubmitHRReject}>
                    Reject Request
                  </Button>
                </Box>
               
            </StyledPaper> )}
            <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{
                    '& .MuiSnackbarContent-root': {
                        fontSize: '1.25rem',
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        color: '#fff',
                        boxShadow: '0 3px 5px rgba(0,0,0,0.3)',
                        padding: '16px 24px',
                        borderRadius: '8px',
                    }
                    }}
                >
                    <Alert onClose={() => setOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                    </Alert>
                </Snackbar>
            <StyledPaper>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Total Reimbursement
                    </Typography>
                    <TextField
                        fullWidth
                        label="Total ($)"
                        variant="outlined"
                        margin="normal"
                        value={'$'+ total}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Date of Submission"
                        variant="outlined"
                        margin="normal"
                        value={new Date(requestDetails.dateOfSubmission).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    <Grid container>
                        <Grid item xs={6} md={6} style={{marginTop: '20px'}}>
                            <div id="manager-approval-badge"  className="badge">
                            <Typography variant="h6" component="h2" gutterBottom>
                              Manager Approval: 
                              </Typography>
                                <div style={styles.container}>
                                    <StyledBadge badgeContent={requestDetails.approvalStatus.managerStatus} status={requestDetails.approvalStatus.managerStatus}></StyledBadge>
                                </div>
                            </div>
                            { requestDetails.approvalStatus.managerStatus == "Approved" && 
                            <div>
                                { new Date(requestDetails.approvalStatus.dateOfManagerApproval).toLocaleDateString() }    
                                < br />
                                { requestDetails.approvalStatus.managerName }
                            </div>
                            }
                             { requestDetails.approvalStatus.managerStatus == "Rejected" && 
                            <div>
                                { new Date(requestDetails.approvalStatus.dateOfManagerApproval).toLocaleDateString() }    
                                < br />
                                { requestDetails.approvalStatus.managerName }
                            </div>
                            }
                        </Grid>
                        <Grid item xs={6} md={6} style={{marginTop: '20px'}}>
                          { requestDetails.approvalStatus.managerStatus !== "Rejected" &&
                           <div id="hr-approval-badge" className="badge">
                            <Typography variant="h6" component="h2" gutterBottom>
                              HR Approval: 
                            </Typography>
                              <div style={styles.container}>
                                  <StyledBadge badgeContent={requestDetails.approvalStatus.hrStatus} status={requestDetails.approvalStatus.hrStatus}></StyledBadge>
                              </div>
                          </div>
                          }
                          { requestDetails.approvalStatus.managerStatus == "Approved" &&  (requestDetails.approvalStatus.hrStatus == "Approved" || requestDetails.approvalStatus.hrStatus == "Rejected") && 
                            <div>
                                { new Date(requestDetails.approvalStatus.dateOfHrApproval).toLocaleDateString() }    
                                < br />
                                { requestDetails.approvalStatus.hrName }
                            </div>
                            }
                        </Grid>
                    </Grid>
                </StyledPaper>
                <StyledPaper>
                <Typography variant="h6" component="h2" gutterBottom>
                        Clauses
                    </Typography>
                {[
                    { label: "I understand that Pharmavite reimbursement of tuition and expenses is limited to a maximum of $5,250 per calendar year. Check to sign and accept.", key: "reimbursementLimit" },
                    { label: "Have you been employed by Pharmavite for the last 90 days?", key: "employed90Days" },
                    { label: "Are you receiving educational assistance from the Veteran’s Administration, Pell Grants or any other formal educational aid programs, whether public or private? If so, you are not eligible for reimbursement under this Tuition Reimbursement program. Benefits provided under this program are to cover monies personally paid by the employee.", key: "otherEducationBenefits" },
                    { label: "I understand in order to be reimbursed for the above courses, I must still be employed at Pharmavite. In addition, I must receive a passing grade of 'C' or better for all undergraduate or trade school courses. For graduate course(s), I must receive a passing grade of 'B' or better. Check to sign and accept.", key: "employmentCondition" },
                    { label: "Agreement Regarding Reimbursement of Education Expenses: In consideration of Pharmavite's offer to pay for and/or reimburse to me certain educational expenses, I agree that, if I voluntarily resign my employment with Pharmavite for any reason on or before one year from the date on which I will complete my classwork, I will pay to Pharmavite an amount equal to the difference between (a) the total monies Pharmavite has paid to others on my behalf and/or reimbursed to me during such one year period for educational expenses and (b) $1,500 ('repayable educational assistance expenses'). I agree that Pharmavite may deduct the amount of such repayable educational assistance expenses from any wages or monies due me at the time of my termination of employment from Pharmavite. I agree to sign, at the request of Pharmavite, a further written consent authorizing Pharmavite to deduct the amount of such repayable educational assistance expenses from my final paycheck. I acknowledge that I have read and understand the terms of this agreement, and that I have freely chosen to agree to these conditions after carefully considering the terms.", key: "globalAgreement" }
                ].map((clause, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" gutterBottom>
                        {clause.label}
                    </Typography>
                    <FormControlLabel
                        control={<Switch checked={requestDetails.clauses[clause.key]} inputprops={{readOnly: true}} />}
                        label={requestDetails.clauses[clause.key] ? "Yes" : "No"}
                    />
                    </Box>
                ))}
                </StyledPaper>
            
            </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default RequestDetails;


const DollarInput = ({ label, value, onChange }) => {
    const handleChange = (e) => {
      const inputValue = e.target.value;
      // Remove non-numeric characters except for the decimal point
      const numericValue = inputValue.replace(/[^0-9.]/g, '');
      // Ensure only one decimal point is allowed
      const validValue = numericValue.split('.').length > 2 ? numericValue.slice(0, -1) : numericValue;
      onChange(validValue);
    };
  
    const handleBlur = () => {
      // Ensure the value is formatted to two decimal places with commas
      const formattedValue = numeral(value).format('0,0.00');
      onChange(formattedValue);
    };
  
    const handleFocus = (e) => {
      if (value === '0.00') {
        onChange('');
      }
    };
  

    return (
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        margin="normal"
        value={value ? `$${value}` : '$0.00'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    );
  };
  